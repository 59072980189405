import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/:Eventurl/Login',
		name: 'Login',
		props:true,
		component: () => import('../views/login.vue')
	},
	{
		path: '/admin',
		name: 'Admin',
		component: () => import('../views/admin/layout.vue'),
		children: [
			{
				path: 'dashboard',
				name: 'AdminDashboard',
				component: () => import('../views/admin/dashboard.vue')
			},
			{
				path: 'company',
				name: 'Admincompany',
				component: () => import('../views/admin/vendor.vue')
			},

			{
				path: 'appsummary',
				name: 'Adminappsummary',
				component: () => import('../views/admin/AppSummary.vue')
			},
			{
				path: 'apptransfer',
				name: 'apptransfer',
				component: () => import('../views/admin/CentralTransfer.vue')
			},
			{
				path: 'appedit',
				name: 'appedit',
				component: () => import('../views/admin/AppEdit.vue')
			},
			{
				path: 'appreport',
				name: 'Adminappreport',
				component: () => import('../views/admin/AppReport.vue')
			},
			{
				path: 'appsummarydetail',
				name: 'Adminappsummarydetail',
				component: () => import('../views/admin/AppSummaryDetail.vue')
			},
			
			{
				path:'appcancel',
				name:'Adminappcancel',
				component:()=> import('../views/admin/Cancelcard.vue')
			},

			{
				path:'rptbasicreport',
				name:'Adminrptbasicreport',
				component:()=> import('../views/admin/RptBasicReport.vue')
			},
			{
				path:'rptcompletereport',
				name:'Adminrptcompletereport',
				component:()=> import('../views/admin/RptCompleteReport.vue')
			},

			{
				path:'rptpolicereport',
				name:'Adminrptpolicereport',
				component:()=> import('../views/admin/RptPoliceReport.vue')
			},

			{
				path:'rptNameSearch',
				name:'rptNameSearch1',
				component:()=> import('../views/admin/RptNameSearch.vue')
			},

			{
				path:'rptcovidreport',
				name:'Adminrptcovidreport',
				component:()=> import('../views/admin/RptCovidReport.vue')
			},
			{
				path:'rptzipReport',
				name:'rptzipReport',
				component:()=> import('../views/admin/RptZipReport.vue')
			},
			{
				path:'rptcardpickupReport',
				name:'rptcardpickupReport1',
				component:()=>import('../views/admin/RptCardPickup.vue')
			},
			{
				path:'rptscannReport',
				name:'rptscannReport1',
				component:()=>import('../views/admin/RptScannReport.vue')
			},
			{
				path:'rptJobtypeSummaryReport',
				name:'rptJobtypeSummaryReport3',
				component:()=>import('../views/admin/AppSummaryJobtypeDetail.vue')
			},
			
			
			

			

			
		]
	},
	{
		path: '/dna',
		name: 'dna',
		component: () => import('../views/admin/DnaLayout.vue'),
		children: [
			{
				path: 'dashboard',
				name: 'dnaDashboard',
				component: () => import('../views/admin/dashboard.vue')
			},
			{
				path: 'company',
				name: 'dnacompany',
				component: () => import('../views/admin/vendor.vue')
			},

			{
				path: 'appsummary',
				name: 'dnaappsummary',
				component: () => import('../views/admin/AppSummary.vue')
			},
			{
				path: 'apptransfer',
				name: 'dnaapptransfer',
				component: () => import('../views/admin/CentralTransfer.vue')
			},
			{
				path: 'appedit',
				name: 'dnaappedit',
				component: () => import('../views/admin/AppEdit.vue')
			},
			{
				path: 'appreport',
				name: 'dnaappreport',
				component: () => import('../views/admin/AppReport.vue')
			},
			{
				path: 'rptJobtypeSummaryReport',
				name: 'rptJobtypeSummaryReport1',
				component: () => import('../views/admin/RptJobtypesummry.vue')
			},
			{
				path: 'appsummarydetail',
				name: 'dnaappsummarydetail',
				component: () => import('../views/admin/AppSummaryDetail.vue')
			},
			
			{
				path:'appcancel',
				name:'dnaappcancel',
				component:()=> import('../views/admin/Cancelcard.vue')
			},

			{
				path:'rptbasicreport',
				name:'dnarptbasicreport',
				component:()=> import('../views/admin/RptBasicReport.vue')
			},
			{
				path:'rptcompletereport',
				name:'dnarptcompletereport',
				component:()=> import('../views/admin/RptCompleteReport.vue')
			},

			{
				path:'rptpolicereport',
				name:'dnarptpolicereport',
				component:()=> import('../views/admin/RptPoliceReport.vue')
			},
			{
				path:'rptNameSearch',
				name:'rptNameSearch2',
				component:()=> import('../views/admin/RptNameSearch.vue')
			},

			{
				path:'rptcovidreport',
				name:'dnarptcovidreport',
				component:()=> import('../views/admin/RptCovidReport.vue')
			},
			{
				path:'rptzipReport',
				name:'dnarptzipReport',
				component:()=> import('../views/admin/RptZipReport.vue')
			},
			{
				path:'rptcardInventory',
				name:'rptcardInventory',
				component:()=> import('../views/admin/RptCardInventory.vue')
			},

			{
				path:'appprint',
				name:'dnaPrintappprint',
				component:()=> import('../views/print/AppPrint.vue')
			},

			{
				path:'appcentralprint',
				name:'Printappcentralprint2',
				component:()=> import('../views/print/AppCentralPrint.vue')
			},

			{
				path:'appmediaprint',
				name:'dnaMediaPrintappprint',
				component:()=> import('../views/print/AppMediaPrint.vue')
			},

			
			{
				path:'app-reprint',
				name:'dnaPrintappre-print',
				component:()=> import('../views/print/AppReprint.vue')
			},

			{
				path:'appcentral-reprint',
				name:'Printappcentralre-print',
				component:()=> import('../views/print/AppCentralReprint.vue')
			},

			{
				path:'appmedia-reprint',
				name:'dnaMediaPrintappre-print',
				component:()=> import('../views/print/AppMediaReprint.vue')
			},


			{
				path:'localscann',
				name:'dnalocalscann',
				component:()=> import('../views/scann/localscann.vue')
			},

			{
				path:'centralscann',
				name:'dnacentralscann',
				component:()=> import('../views/scann/centralscann.vue')
			},

			{
				path:'mediascann',
				name:'dnamediascann',
				component:()=> import('../views/scann/mediascann.vue')
			},


			{
				path:'mediarescann',
				name:'dnamediarescann',
				component:()=> import('../views/scann/mediaRescann.vue')
			},
			{
				path:'localrescann',
				name:'dnalocalrescann',
				component:()=> import('../views/scann/localRescann.vue')
			},
			{
				path:'rptcardpickupReport',
				name:'rptcardpickupReport',
				component:()=>import('../views/admin/RptCardPickup.vue')
			},
			{
				path:'rptscannReport',
				name:'rptscannReport2',
				component:()=>import('../views/admin/RptScannReport.vue')
			},
			{
				path:'rptJobtypeSummaryReport',
				name:'rptJobtypeSummaryReport2',
				component:()=>import('../views/admin/RptJobtypesummry.vue')
			},
			{
				path:'appimport',
				name:'appimport',
				component:()=>import('../views/admin/AppImport.vue')
			},



			
		]
	},
	{
		path: '/print',
		name: 'printAdmin',
		component: () => import('../views/print/layoutPrint.vue'),
		children: [
			{
				path: 'dashboard',
				name: 'PrintDashboard',
				component: () => import('../views/admin/dashboard.vue')
			},
			
			{
				path:'appprint',
				name:'Printappprint',
				component:()=> import('../views/print/AppPrint.vue')
			},
			{
				path:'appcentralprint',
				name:'Printappcentralprint1',
				component:()=> import('../views/print/AppCentralPrint.vue')
			},

			{
				path:'appmediaprint',
				name:'MediaPrintappprint',
				component:()=> import('../views/print/AppMediaPrint.vue')
			},

			
			{
				path:'app-reprint',
				name:'Printappre-print',
				component:()=> import('../views/print/AppReprint.vue')
			},
			{
				path:'appcentral-reprint',
				name:'Printappcentralre-print1',
				component:()=> import('../views/print/AppCentralReprint.vue')
			},


			{
				path:'appmedia-reprint',
				name:'MediaPrintappre-print',
				component:()=> import('../views/print/AppMediaReprint.vue')
			},
			

			{
				path:'rptbasicreport',
				name:'Printrptbasicreport',
				component:()=> import('../views/admin/RptBasicReport.vue')
			},
			{
				path:'rptcompletereport',
				name:'Printrptcompletereport',
				component:()=> import('../views/admin/RptCompleteReport.vue')
			},

			{
				path:'rptpolicereport',
				name:'Printrptpolicereport',
				component:()=> import('../views/admin/RptPoliceReport.vue')
			},

			{
				path:'rptcovidreport',
				name:'Printrptcovidreport',
				component:()=> import('../views/admin/RptCovidReport.vue')
			},


			{
				path:'localscann',
				name:'localscann',
				component:()=> import('../views/scann/localscann.vue')
			},
			{
				path:'centralscann',
				name:'centralscann',
				component:()=> import('../views/scann/centralscann.vue')
			},

			{
				path:'mediascann',
				name:'mediascann',
				component:()=> import('../views/scann/mediascann.vue')
			},

			{
				path:'localrescann',
				name:'localrescann',
				component:()=> import('../views/scann/localRescann.vue')
			},
			{
				path:'centralrescann',
				name:'centralrescann',
				component:()=> import('../views/scann/centralRescann.vue')
			},

			{
				path:'mediarescann',
				name:'mediarescann',
				component:()=> import('../views/scann/mediaRescann.vue')
			},
			



		]
	},


	
	{
		path: '/media',
		name: 'Media',
		component: () => import('../views/media/layout.vue'),
		children: [
			{
				path: 'dashboard',
				name: 'MediaDashboard',
				component: () => import('../views/media/dashboard.vue')
			},
			
			{
				path: 'appreport',
				name: 'Mediaappreport',
				component: () => import('../views/media/AppReport.vue')
			},

			{
				path: 'mediaappcancel',
				name: 'Mediacancel',
				component: () => import('../views/media/AppCancel.vue')
			},

			{
				path: 'mediaapptransfer',
				name: 'mediaapptransfer',
				component: () => import('../views/media/Transfer.vue')
			},

			{
				path:'mediabasicreport',
				name:'mediabasicreport',
				component:()=> import('../views/media/RptBasicreport.vue')
			},

			{
				path:'mediacompletereport',
				name:'mediacompletereport',
				component:()=> import('../views/media/RptCompletereport.vue')
			},

			{
				path:'mediapolicereport',
				name:'mediapolicereport',
				component:()=> import('../views/media/RptPoliceReport.vue')
			},

			{
				path:'mediacovidreport',
				name:'mediacovidreport',
				component:()=> import('../views/media/RptCovidReport.vue')
			},

			{
				path:'mediamatchdayreport',
				name:'mediamatchdayreport',
				component:()=> import('../views/media/RptMatchdayreport.vue')
			},
			{
				path:'mediarptzipReport',
				name:'mediarptzipReport',
				component:()=> import('../views/media/RptZipReport.vue')
			},


		]
	},


	{
		path: '*',
		name: 'NoFound',
		component: () => import('../views/notfound.vue'),
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
